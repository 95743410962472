.cover{
    top: -100vh;
    left: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgb(255, 253, 247);;
    z-index: 15;
    font-family: "VT323", serif;
    font-style: normal;
}


.slide-down{
    animation: slide-down 0.75s ease forwards;
}

.slide-down-h1{
    animation:text-animation 0.75s ease forwards;
}

@keyframes text-animation {
    0%{
        scale: 0;
    }
    70%{
        scale: 1.1;
        opacity: 1;
    }
    100%{
        scale: 1;
        opacity: 1;
    }
  }

.cover h1{
    opacity: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 5%;
    font-size: 100px;
    font-weight: 500;
    color: rgb(0, 0, 0);   
}


@keyframes slide-down {
    100%{
        top:0px;
    }
    0%{
        top:-100vh;
    }
}

.slide-up{
    animation: slide-up 1s ease 2s forwards;
  }
  
  @keyframes slide-up {
    0%{
        top:0px;
    }
    100%{
        top:-100vh;
    }
  }


  @media only screen and (max-width: 600px) {
        .cover h1{
            opacity: 0;
            position: absolute;
            bottom: 10%;
            right: 0;
            margin: 5%;
            font-size: 70px;
            font-weight: 500;
            color: rgb(0, 0, 0);   
        }

  }