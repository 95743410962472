body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
}


/* .arrow-right{
  position: relative;
  left: 90%;
  transform: translate(-100%);
  top:100px;

}

.arrow-left{
  position: relative;
  left: 0%;
  transform: scaleX(-1);
  top:100px;

} */

body::before{
  content: "";
  width: 10px;
  height: 10px;
  background-color: red;
  top: 50%;
  left: 50%;
}

#contianer{
  width: 100%;
  height: 100vh;  
  position: relative;
  overflow: hidden;
}
/* .bubbles{
  position: relative;
  display: flex;
}
.bubbles span{
  opacity: 1;
  position: relative;
  top:-40vh;
  width: 30px;
  height: 30px;
  background: rgba(255, 230, 0, 0.34);
  border-radius: 50%;
  box-shadow: 0 0 0 10px rgba(136, 146, 0, 0.208),
  0 0 50px rgb(255, 234, 0),
  0 0 100px rgb(255, 234, 0);
  animation: rain 15s linear infinite;
  animation-delay: calc(125s/ var(--i));
}


.bubbles span:nth-child(even){
  background: rgba(34, 127, 11, 0.34);
  box-shadow: 0 0 0 10px rgba(6, 72, 3, 0.208),
  0 0 50px rgb(62, 145, 61),
  0 0 100px rgb(62, 145, 61);
} */


.profile-magic{
  display: none;
}
/* @keyframes rain {
  0%{
    transform: translateY(140vh) scale(0);
  }
  100%{
    transform: translateY(-10vh) scale(1);
  }
} */
.larger-wrapper{
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 35%;
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.profile{
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 70%;
  width: 30vw;
} 
.profile-wrapper{
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 70%;
  width: 30vw;
  z-index: -1;
}
.title{
  width: 40vw;
  font-size: 70px;
  margin: 0;
}
pre{
  font-size: 15px;
  animation: modified-pop 2s ease forwards;
}
.wonders{
    background-color: rgb(39, 167, 7);
    height: 90px;
    color: white;
    box-shadow: 5px 5px black;
    font-size: 30px;
    font-weight: bold;
    width: 80vw;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    z-index: 2;
    left: 50%;
    position: absolute;
}

.wonders:hover{
  background-color: rgb(46, 100, 2);
}
.title-magic{
  font-family: "VT323", serif;
  font-weight: 400;
  font-style: normal;
  animation: pop 0.3s ease 1;
}

@keyframes pop {
  0%{
      scale: 1;
  }
  70%{
      scale: 1.1;
  }
  100%{
      scale: 1
  }
}

@keyframes modified-pop {
  0%{
      scale: 1;
      opacity: 0;
  }
  60%{
    opacity: 0.3;
  }
  70%{
      scale: 1.5;
  }
  80%{
    opacity: 0.8;
  }
  100%{
      scale: 1;
      opacity: 1;
  }
}

@keyframes fade-in-out{
  0%{
    opacity: 0;
  }
  30%{
    opacity: 0.5;
  }
  50%{
    opacity: 1;
  }
  80%{
    opacity: 0.5;
  }
  100%{
    opacity: 0;
  }
}

@media only screen and (max-width: 600px) {
  .profile{
    display: none;
  }
  .wonders{
    background-color: rgb(39, 167, 7);
    height: 90px;
    color: white;
    box-shadow: 5px 5px black;
    font-size: 30px;
    font-weight: bold;
    width: 80vw;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    z-index: 2;
    left: 50%;
    position: absolute;
  }
  .larger-wrapper{
    position: absolute;
    transform: translate(-50%,-50%);
    width: fit-content;
    top: 40%;
    left: 50%;
    display: flex;
    gap: 30px;
    flex-direction: column;
  }
  .title{
    width: 80vw;
    font-size: 55px;
    margin-bottom: 33.5px;
    margin-top: 33.5px;
    text-align: center;
  }
  .profile-wrapper{
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    width: auto;
  }
  pre{
    font-size: 20px;
    color: rgb(153, 171, 152);
  }

}