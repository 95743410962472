.iframe-pdf{
    border: 5px solid black;

}

.back-icon{
    width: 55px;
    height: 55px;
    margin: 30px;
    left: 0;
    position: absolute;
    cursor: pointer;
}




.reviews-main-wrapper{
    margin-bottom: 100px;
    display: flex;
    gap: 100px;
}
.reviews-arrow{
    width: 50px;
    cursor: pointer;
}


.title-top{
    margin-top: 50px;
    text-align: center;
    font-family: "VT323", serif;
}

.second-title{
    margin-top: 20px;
}

.resume-main{
    background-color: rgb(255, 253, 247);
    min-height: 100vh;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:30px;
}

@media only screen and (max-width: 600px) {
    .iframe-pdf{
        width: 80vw;
        min-height: 50vh;
    }
    .reviews-arrow{
        width: 30px;
        cursor: pointer;
    }
    .reviews-main-wrapper{
        margin-bottom: 100px;
        display: flex;

        gap: 20px;
    }
    .back-icon{
        width: 30px;
        height: 30px;
        margin: 10px;
        left: 0;
        cursor: pointer;
        animation: pop 0.5s ease forwards;
    }
    .mobile-top-title{
        margin-top: 100px;
        text-align: center;
        font-family: "VT323", serif;
    }
}